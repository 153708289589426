import React from "react";
//import ReactDOM from "react-dom"; >>> .findDOMNode(this)
import { uid } from "react-uid";
import Wrapper from "App/components/common/basic/wrapper";

// OpenLayers
import MapSource from "ol/source/OSM";
import * as Ol from "ol";
import * as Proj from "ol/proj";
import * as Layers from "ol/layer";
import * as Controls from "ol/control";

class AppMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapid: `${uid(props.view)}-${new Date().getTime()}`
        };
    }

    componentDidMount() {
        this.init();
    }

    init() {
        const { view } = this.props;
        const { mapid } = this.state;
        //let mapDOM = document.querySelector("#map");

        this.c_scaleline = new Controls.ScaleLine();
        this.c_fullscreen = new Controls.FullScreen();

        this.layers = [
            new Layers.Tile({
                source: new MapSource({ crossOrigin: "*" }),
            })
        ];

        this.map = new Ol.Map({
            layers: this.layers,
            target: `map-${mapid}`,
            view: new Ol.View(view),
            controls: Controls.defaults().extend([this.c_scaleline, this.c_fullscreen]),
        });
    }

    render() {
        const { mapid } = this.state;

        return (
            <div className="app__map" key={`map-wrapper-${mapid}`}>
                <div id={`map-${mapid}`} className={`map map-${mapid}`} />
            </div>
        );
    }
}

AppMap.defaultProps = {
    view: {
        projection: 'EPSG:3857',
        center: Proj.fromLonLat([24.97, 45.90]),
        zoom: 7,
    }
};

export default Wrapper(AppMap);