import React from "react";
import { Link } from "react-router-dom";

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currYear: new Date().getFullYear() };
    }

    render() {
        const { currYear } = this.state;

        return (
            <footer className="page-footer indigo darken-2">
                <div className="container">

                    <div className="row">
                        <div className="col l6 s12">
                            <h5 className="white-text">About</h5>

                            <p className="grey-text text-lighten-4">
                                Apps Platform.
                            </p>
                        </div>

                        <div className="col l3 s12">
                            <h5 className="white-text">Links</h5>
                            <ul>
                                <li><Link className="white-text" to="/login">Login</Link></li>
                                <li><Link className="white-text" to="/about">About</Link></li>
                            </ul>
                        </div>

                        <div className="col l3 s12">
                            <h5 className="white-text">Connect</h5>
                            <ul>
                                <li><a className="white-text" target="_blank" href="https://twitter.com/hagik15">Twitter</a></li>
                                <li><a className="white-text" target="_blank" href="https://facebook.com/hagik15">Facebook</a></li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="footer-copyright">
                    <div className="container">
                        &copy; 2020-{currYear} Modsite by <a href="https://twitter.com/haritonandrei" target="_blank">Hariton Andrei Marius</a>.
		            </div>
                </div>
            </footer>
        );
    }
}

export default Footer;