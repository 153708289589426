import React from "react";

function Wrapper(Component) {
    return (props) => {
        try {
            return <Component {...props} />;
        }
        catch (error) {
            return <div className="error">{error}</div>;
        }
    };
}

export default Wrapper;