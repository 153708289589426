import React from "react";
import { Route, Switch } from "react-router-dom";
import AppMap from "App/components/apps/map";
import PageHomepage from "App/components/pages/homepage";
import PageAbout from "App/components/pages/about";
import PageError from "App/components/pages/error";

class Switcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Switch>
                <Route path={"/map"} component={AppMap} />
                <Route path={"/about"} component={PageAbout} />
                <Route exact path={"/"} component={PageHomepage} />
                <Route component={PageError} />
            </Switch>
        );
    }
}

export default Switcher;