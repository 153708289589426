import React from "react";
import { Link } from "react-router-dom";
import { uid } from "react-uid";

import Wrapper from "App/components/common/basic/wrapper";
import Header from "App/components/utils/header";
import Footer from "App/components/utils/footer";
import APPS from "App/configuration/common/apps.json";

class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Header />

                <div className="section no-pad-bot" id="index-banner">

                    <section className="container">
                        <h1 className="header center indigo-text">Welcome on Modsite!</h1>

                        <div className="row center">
                            <h5 className="header col s12 light">Here you find applications like Map, Shop Manager, and other apps.</h5>
                        </div>
                    </section>

                    <section className="container">
                        <div className="section">
                            <h4 className="header indigo-text">Apps</h4>

                            <section className="row">
                                {APPS.map((app, index) => {
                                    return (
                                        <div key={uid({ app, index })} className="col s12 m6">
                                            <div className="card indigo">
                                                <div className="card-content white-text">
                                                    <span className="card-title">{app.name}</span>
                                                    <p>{app.description}</p>
                                                </div>

                                                <div className="card-action">
                                                    {app.link.local
                                                        ? <Link to={app.link.path} className="white-text">{app.link.text}</Link>
                                                        : <a href={app.link.path} className="white-text" target="_blank">{app.link.text}</a>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </section>
                        </div>
                    </section>

                </div>

                <Footer />
            </>
        );
    }
}

export default Wrapper(Homepage);