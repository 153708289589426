import React from "react";
import Wrapper from "App/components/common/basic/wrapper";

import Header from "App/components/utils/header";
import Footer from "App/components/utils/footer";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                <Header />

                <div className="section no-pad-bot" id="index-banner">

                    <section className="container">
                        <h1 className="header center indigo-text">Apps Platform</h1>

                        <div className="row center">
                            <h5 className="header col s12 light">Here you find applications like Map, Shop Manager, and other apps.</h5>
                        </div>
                    </section>

                </div>

                <Footer />
            </>
        );
    }
}

export default Wrapper(About);