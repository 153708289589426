import React from "react";
import { BrowserRouter } from "react-router-dom";

import Wrapper from "App/components/common/basic/wrapper";
import Switcher from "App/components/common/routing/switcher";

class Router extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <BrowserRouter>
                <div className="page">
                    <Switcher />
                </div>
            </BrowserRouter>
        );
    }
}

export default Wrapper(Router);