import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "App/components/common/basic/wrapper";

import Header from "App/components/utils/header";
import Footer from "App/components/utils/footer";

const MIN_ERR_CODE = 100;
const MAX_ERR_CODE = 900;

class Error extends React.Component {
    constructor(props) {
        super(props);
        this.state = { errCode: 0 };
    }

    componentDidMount() {
        let errUrl = window.location.pathname.split("/");
        let code = parseInt(errUrl[errUrl.length - 1]);

        if (code !== NaN && code > MIN_ERR_CODE && code < MAX_ERR_CODE) {
            this.setState({ errCode: code });
        }
    }

    render() {
        const { errCode } = this.state;

        return (
            <>
                <Header />

                <div className="section no-pad-bot" id="index-banner">

                    <section className="container">
                        <h1 className="header center indigo-text">Error{errCode ? ` ${errCode}` : ""}!</h1>

                        <div className="row center">
                            <h5 className="header col s12 light">There was an error.</h5>
                        </div>
                    </section>

                </div>

                <Footer />
            </>
        );
    }
}

export default Wrapper(Error);