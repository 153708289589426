import React, { PureComponent } from "react";
import Router from "App/components/common/routing/router";

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (<Router />);
    }
}

export default App;